// z-indexing
body.cmsbackend {

	.page-wrap, .pizza-col {
		@include breakpoint(large) {
			position: relative;
		    z-index: 100;
		}	
	}

	.page-navi {
		@include breakpoint(large) {
			z-index: 90;
		}	
	}

}

// Styled list
dl.floatList {
	@include pie-clearfix;

	dt, dd {
		display: block;
		vertical-align: top;
		float: left;
	}

	dt {
		padding-right: .5rem;
		width: 40%;
		clear:left;
	}

	dd {
		width: 60%;
	}
}

// SOME HELPFUL CLASSES
//////////////////////////////

.highlight {
	color: $secondary;
}

.btn {
	background: $medium;
	border: none;
	color: $dark;
	cursor: pointer;
	display: table;
	font-size: 1rem;
	line-height: 1rem;
	padding: .7rem 1rem;
	text-align: center;
	text-decoration: none;
	transition: 300ms all ease-in-out;

	&:hover, &:active, &:focus {
		background: $dark;
		color: $light;
	}

	*[class^="icon"] {
		margin-left: .5rem;
		vertical-align: middle;
	}
}

.notification {
	padding: 1em;
	background: $alert;
	color: $light;
	font-size: 1.2em;
}

// PAGE WRAP
//////////////////////////////

.page-wrap {
	padding-top:rem($menu-bar);
	
	@include breakpoint(large) {
		padding-top: 108px;
	}
}

// HEADER
//////////////////////////////

.header {
	background: $light;
	position: relative;
	padding: 0;
	width: 100%;
	height: 21.75rem;

	@include breakpoint(supertiny) {
		height: 29.75rem;
	}

	@include breakpoint(tiny) {
		height: 20.3rem;
	}

	@include breakpoint(small) {
		height: 29.25rem;
	}

	@include breakpoint(medium) {
		height: 66vw;
		max-height: 57.9rem;
	}

	body:not(.index) & {

		@include breakpoint(small) {
			height: 23.25rem;
		}

		@include breakpoint(medium) {
			height: 50vw;
    		max-height: 30.5rem;
		}

		@include breakpoint(giant) {
			height: 50vw;
			max-height: 33.75rem;
		}


	}

	a.branding-content {
	    width: auto;
	    height: auto;
	    z-index: 15;
	    position: absolute;
		padding: 20px;
  		left: 50%;
  		transform: translatex(-50%);
  		margin: 55px 0;

  		@include breakpoint(medium) {
  			display: none;
  		}
  	}

	.h-bg{
		width: 60%;
		height: 20%;
	    z-index: 10;
	    position: absolute;
	    background-color: $light;
	    filter: blur(20px);
  		left: 50%;
  		transform: translatex(-50%);
  		margin: 50px 0;

		@include breakpoint(tiny) {
			height: 40%;
		}

		@include breakpoint(small) {
			height: 38%;
		}

	    @include breakpoint(medium) {
			display: none;
		}
	}


	// SLIDESHOW NO-JS FALLBACK

	.nojs & {

		.slideshow {
			background-image: url(../images/slider/fid-werne-1-tiny.jpg);

			@include breakpoint(medium) {
				background-image: url(../images/slider/fid-werne-1-mobile.jpg);
			}

			@include breakpoint(large) {
				background-image: url(../images/slider/fid-werne-1.jpg);
			}
		}
	}

	// SLIDESHOW

	.slideshow {
		position: absolute;
		display: block;
		width: 100%;
		height: 6.25rem;
		top: 0;
		left: 0;
		z-index: 1;

		@include breakpoint(supertiny) {
			height: 12.8125rem;
		}

		@include breakpoint(small) {
			height: 21.75rem;
		}

		@include breakpoint(medium) {
			height: 55vw;
			max-height: 50rem;
		}

		body:not(.index) & {
			
			@include breakpoint(small) {
				height: 15.75rem;
			}

			@include breakpoint(medium) {
				max-height: 24rem;
				height: 40vw;
			}
		}


		.slick-track, .slick-list, .slide-ctn {
			width: 100%;
			height: 100%;
		}

		.slide-ctn {
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			display: inline-block;

			&.slide-01 {
				background-image: url(../images/slider/fid-werne-1-tiny.jpg);

				@include breakpoint(medium) {
					background-image: url(../images/slider/fid-werne-1-mobile.jpg);
				}

				@include breakpoint(large) {
					background-image: url(../images/slider/fid-werne-1.jpg);
				}
			}
		}
	}

	// CLAIM

	.claim {
		display: none;
		width: 100%;
		position: absolute;
		z-index: 10;
		text-align: center;
		top: 7rem;

		@include breakpoint(medium) {
			display: inline-block;
		}

		@include breakpoint(medium) {
			top: 20vw;
		}

		@include breakpoint(huge) {
			top: 24.0625rem;
		}

		div {
			padding: 20px;
			display: inline-block;
		}

		body:not(.index) & {
			top: 8.2vw;

			@include breakpoint(large) {
				top: 6vw;
			}

			@include breakpoint(huge) {
				top: 3vw;
			}
		}

		div:first-child {
			font-family: $display-font;
			color: $primary;
			background-color: $light;
			font-size: 28px;

			@include breakpoint(large) {
				font-size: 46px;
			}
		}

		div:last-child {
			font-family: $main-font;
			background-color: rgba(8, 171, 52, .9);
			color: $light;
			font-size: 17px;
			margin-top: 0.3rem;

			@include breakpoint(large) {
				font-size: 24px;
			}

			@include breakpoint(huge) {
				margin: 0.75rem 20% 0 20%;
			}
		}
	}

	// TEASER

	.h-teaser {
	    position: absolute;
	    display: inline-block;
	    width: 100%;
	    bottom: 0;

	    @include breakpoint(tiny) {
	    	display: flex;
	    	flex-wrap: wrap;
	    	justify-content: space-between;
	    }

	    @include breakpoint(medium) {
	    	z-index: 90;
	    }

	    @include breakpoint(large) {
			max-width: 1620px;
		    left: 50%;
		    transform: translateX(-50%);
		}

		@include breakpoint(huge) {
			padding: 0 6%;
		}

		li {
			overflow: hidden;
			background-color: transparent !important;
			display: block;

			@include breakpoint(tiny) {
				flex-basis: 50%;
				display: inline-flex;
			}

			@include breakpoint(medium) {
				flex-basis: auto;
			}

			&:nth-child(3) {

				@include breakpoint(tiny) {
					order: 1;
				}

				@include breakpoint(medium) {
					order: initial;
				}
			}

			&:nth-of-type(odd) {
				a {
					background-color: rgba(63, 165, 54, .75);
				}
			}

			&:nth-of-type(even) {
				a{
					
					&:before {
						background-color: rgba(63, 165, 54, .85);
					}
				}
			}

			&:nth-of-type(1) {
				a {
					background-position: 0% top;
				}
			}

			&:nth-of-type(2) {
				a {
					background-position: 33.33% top;
				}
			}

			&:nth-of-type(3) {
				a {
					background-position: 66.66% top;
				}
			}

			&:nth-of-type(4) {
				a {
				background-position: 100% top;
				}
			}

			a {
				background-position: center center;
				width: 100%;
				height: 20vw;
				display: inline-block;
				position: relative;
				transition: all .3s ease-in;
				max-height: 60px;
				background-color: $primary;


				@include breakpoint(medium) {
					height: 22vw;
					width: 22vw;
					max-width: 19.25rem;
					max-height: 19.25rem;
					border-radius: 99rem;
					background-size:cover;
					background-image: url(../images/layout/bg-teaser.png);
				}

				&:hover {
					background-color: $secondary;

					&:before, span {
						@include breakpoint(large) {
							opacity: 0;
							visibility: hidden;
						}
					}

				}

				&:before {
					border-radius: 99rem;
					background-color: rgba(141, 214, 82, 0.85);
					display: inline-block;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					transition: all .3s ease-in;
					opacity: 1;
					visibility: visible;


					@include breakpoint(medium) {
						content: "";
					}
				}

				span {
					position: absolute;
					bottom: 50%;
					left: 50%;
					transform: translate(-50%, 50%);
					text-decoration: none;
					color: $light;;
					font-size: 1.3rem;
					font-family: $display-font;
					width: 100%;
	    			text-align: center;


	    			@include breakpoint(large) {
	    				font-size: 22px;
	    				transition: all .3s ease-in;
						opacity: 1;
						visibility: visible;

	    			}

	    			@include breakpoint(giant) {
	    				font-size: 26px;
	    			}

	    			@include breakpoint(huge) {
	    				font-size: 30px;
	    			}

	    			small {
	    				display: none;

	    				@include breakpoint(medium) {
	    					display: block;
	    					font-family: $main-font;
	    					font-size: .95rem;
	    					position: relative
	    				}

	    				@include breakpoint(medium) {
	    					padding-top: 7px;
							margin-top: 10px;
	    				}

	    				@include breakpoint(large) {
	    					padding-top: 14px;
							margin-top: 20px;
	    				}

	    				@include breakpoint(giant) {
	    					font-size: 1.125rem;

	    				}

	    				&:before {

	    					@include breakpoint(medium) {
	    						content: "";
	    						position: absolute;
	    						left: 50%;
	    						top: 0;
	    						transform: translateX(-50%);
	    						display: inline-block;
	    						width: 75%;
	    						height: 1px;
	    						background-color: $light;

	    					}
	    				}
	    			}
				}
			}
		}
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;
	padding: 8vw 0;

	@include breakpoint(small) {
		padding: 8vw 0 12vw;
	}

	@include breakpoint(medium) {
		padding: 12vw 0 0 0;
	}

	@include breakpoint(giant) {
		padding: 7.5rem 0 0 0;
	}

	.google-maps {
		@extend .video-container;

		@include breakpoint(medium) {
			height: rem(400px);
		}
	}

	.video-container {
		margin-bottom: 1rem;
	}

	p {
		color: $secondary;
		line-height: 165%;}

	a {
		color: $primary;
		transition: all .3s linear;

		&:hover {color: $secondary;}
	}

	img {
		margin-bottom: 1rem;
	}

	ul {
		margin-bottom: 1rem;

		li {
			padding: 0.5rem 0 0.5rem 1.5625rem;
			position: relative;

			@include breakpoint(medium) {
				padding: 0.8rem 0 0.8rem 1.5625rem;
			}

			&:last-child {
				border-bottom: none;
			}

			&:before {
				content: "";
				background-image: url(../images/layout/bg-li.png);
				background-size: cover;
				background-position: right center;
				width: 0.9375rem;
    			height: 1.1875rem;
				display: inline-block;
				position: absolute;
				left: 0;

				@include breakpoint(medium) {
	    			width: 1.0625rem;
					height: 1.4375rem;
				}
			}
		}

		&.smli {
			li {
				&:before {
					transform: scale(.7);
					background-position: center center;
				}
				small {
					display: block;
				}
			}
		}
	}

	.pizza-col {
		position:relative;

		&:after {

			@include breakpoint(medium) {
				content: "";
				position: absolute;
				display: inline-block;
				height: calc(711px / 1);
				width: calc(738px / 2.5);
				bottom: 0;
				right: 0;
				background-size: cover;
				background-image: url(../images/layout/bg-pizza.png);
				background-repeat: no-repeat;
			}

			@include breakpoint(large) {
				// height: calc(711px / 1);
				width: calc(738px / 1.75);
			}

			@include breakpoint(huge) {
				// height: calc(711px / 1);
				width: calc(738px / 1);
			}
		}

		& > .row {
			@include breakpoint(medium) {
				padding-bottom: 8vw;
			}
		}
	}

	.soup-col {
		position:relative;
		overflow: hidden;

		&:before {

			@include breakpoint(medium) {
				content: "";
			    position: absolute;
			    display: inline-block;
			    height: 100%;
			    width: calc(646px / 2.5);
			    bottom: 0;
			    left: 0;
			    background-size: cover;
			    background-image: url(../images/layout/bg-soup.png);
			    background-position: right center;
			    background-repeat: no-repeat;
			}

			@include breakpoint(large) {
				// height: calc(661px / 1);
				width: calc(646px / 1.75);
			}

			@include breakpoint(huge) {
				// height: calc(661px / 1);
				width: calc(646px / 1);
			}
		}

		& > .row {
			@include breakpoint(medium) {
				padding: 8vw 0;
			}
		}
	}
	a.buttonlink {
		display: inline-block;
		width: 100%;
		padding: .5rem;
		background-color: $primary;
		color: $light;
		transition: all .3s ease-in;
		text-align: center;
		text-decoration: none;
		margin-bottom: .5rem;
		
		&.layer3 {
			background-color: #5086bc;
		}

		&.layer2 {
			background-color: #f39c43;
		}

		&.layer1 {
			background-color: #a93c4d;
		}

		i {
			margin-left: .5rem;
		}

		&:hover {
			background-color: $secondary;
		}
	}

	.spacer {
		margin-bottom: 8vw;
	}

	// CONTENT - STANDARTS

    .anfahrt & {

        input {
        	background-color: #eae9e9 !important;
        }

        textarea {
        	background-color: #eae9e9 !important;
        }

        #submit {
        	width: 40%;
        	transition: all .3s linear;
        	float: left;
        }

        #saddr {
        	width: 58%;
        	display: inline-block;
        	padding: 0 0 0 .5rem;
        	float: left;
        }

        iframe {
        	width: 100%;
        	border: none;
        	height: 17.625rem;
        }

    }

	.kontakt & {

		button {
			padding: .5rem;
			transition: all .3s linear;
		}

		label[for="Datenschutz"] {
			display: inline-block;
			width: 80%;
		}
				
		.label-message {
			padding-top: 0;
		}
		
		#Nachricht {
			height: 22.4rem;
		}

		hr {
			margin: 3rem 0 2rem;
		}
		
		fieldset {
			margin-bottom: 0 !important;
		}

		textarea, input, select {
			border: 1px solid #999 !important;
		}
	}

	// TEASER

	.teaser {
	    width: 100%;
	    background-size: cover;
	    padding: 8vw 0;
	    margin: 8vw 0;

		&.lazyLoaded{
			background-image: url(../images/layout/bg-c-teaser-tiny.jpg);
		}

	    @include breakpoint(medium) {
	    	margin: 0;
	    	height: 36vw;
	    	padding: 0;
	    	max-height: 33.75rem;

			&.lazyLoaded{
				background-image: url(../images/layout/bg-c-teaser-mobile.jpg);
			}
	    }

		@include breakpoint(large) {
			&.lazyLoaded{
				background-image: url(../images/layout/bg-c-teaser.jpg);
			}
		}

		.row {
			position: relative;

			@include breakpoint(tiny) {
				display: flex;
    			justify-content: space-around;
			}

			.lightbox-image:before {
				opacity: 0.001;
			}

			.auflauf {
				display: none;

				@include breakpoint(tiny) {
					display: inline-block;
				}

				@include breakpoint(medium) {
				    top: 1vw;
				    left: 52.5%;
    			}

    			@include breakpoint(giant) {
    				left: 57.5%;
    			}

				a {

					@include breakpoint(medium) {
	    				max-width: 14.6875rem;
					    max-height: 14.6875rem;
					}
				}
			}

			.smoothie {
				margin: 0 auto;

				@include breakpoint(medium) {
					margin: 0;
					top: 3vw;
					left: 25%;
				}

				@include breakpoint(giant) {
					left: 28%;
				}

				a {
					margin: 8vw 0;

					@include breakpoint(medium) {
						margin: 0;
	    				max-width: 22.8125rem;
					    max-height: 22.8125rem;
					}
				}
			}

			.rezepte {

				@include breakpoint(medium) {
					top: 0;
					left: 3.3%;
					transform: translateY(-55px);
				}

				a {
	    			@include breakpoint(medium) {
	    				max-width: 19.75rem;
					    max-height: 19.75rem;
					    background-image: url(../images/layout/bg-rezeptecke.png);
	    			}

	    			&:before {
	    				@include breakpoint(medium) {
	    					background-color: rgba(63, 165, 54, .75);
	    				}
	    			}
    			}
			}

			.funktionen {

				@include breakpoint(medium) {
					top: 12vw;
				    left: 77.5%;
				}

				a {
	    			@include breakpoint(medium) {
	    				max-width: 19.0625rem;
					    max-height: 19.0625rem;
					    background-image: url(../images/layout/bg-funktionen.png);
	    			}

	    			&:before {
	    				@include breakpoint(medium) {
	    					background-color: rgba(141, 214, 82, .75);
	    				}
	    			}
    			}
			}

			.smoothie, .auflauf, .rezepte, .funktionen {
				@include breakpoint(medium) {
					position: absolute;
				}
			}

			.smoothie, .auflauf {

				@include breakpoint(tiny) {
					margin: 0;
				}

				a {
					@include breakpoint(tiny) {
						margin: 1.5rem 0;
						width: 22vw;
					}
				}
			}

			.rezepte, .funktionen {
				display: inline-block;
				width: 100%;
				padding: 0 1rem;

				@include breakpoint(medium) {
					width: auto;
   					padding: 0;
				}		

				a {
					width: 100%;
				    display: inline-block;
				    transition: all .3s ease-in;
				    background-color: $primary;
				    text-align: center;
				    padding: .75rem 0;
				    text-decoration: none;

					@include breakpoint(medium) {
					    width: 20vw;
					    height: 20vw;
					    border-radius: 99rem;
						position: relative;
						background-size: cover;
						overflow: hidden;
	    			}

					&:hover {
						background-color: $secondary;

						&:before, span {
							@include breakpoint(medium) {
								opacity: 0;
								visibility: hidden;
							}
						}
					}

					&:before {
						display: inline-block;
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
						transition: all .3s ease-in;

						@include breakpoint(medium) {
							content: "";
						}

					}

					span {
						color: $light;
						font-size: 1.3rem;
						font-family: $display-font;
						transition: all .3s ease-in;


						@include breakpoint(medium) {
							position: absolute;
							bottom: 50%;
							left: 50%;
							transform: translate(-50%, 50%);
							width: 80%;
						}

						@include breakpoint(large) {
							font-size: 1.8rem;
						}

						@include breakpoint(huge) {
							font-size: 2.625rem;
						}

		    			small {
		    				display: none;

		    				@include breakpoint(medium) {
		    					display: block;
		    					font-family: $main-font;
								position: relative;
							    padding-top: .8rem;
							    margin-top: 1.8rem;	
							    font-size: 1.3rem	
							}


		    				@include breakpoint(huge) {
		    					font-size: 1.5rem;
		    				}

		    				&:before {

		    					@include breakpoint(large) {
		    						content: "";
		    						position: absolute;
		    						left: 50%;
		    						top: 0;
		    						transform: translateX(-50%);
		    						display: inline-block;
		    						width: 100%;
		    						height: 1px;
		    						background-color: $light;

		    					}
		    				}
		    			}
					}
				}
			}
		}
	}
}

// FOOTER
//////////////////////////////

.footer {
    display: inline-block;
    width: 100%;
    background-color: #19933a;

    @include breakpoint(small) {
    	background-size: cover;
    	background-image: url(../images/layout/bg-footer-mobile.jpg);
    	height: 30rem;
    	position: relative;
    }

    @include breakpoint(medium) {
    	background-image: url(../images/layout/bg-footer.jpg);
    }

    .mapoverlay {
    	padding: 8vw 0;

    	a {
			transition: all .3s ease-in;

			&:hover {
				color: $secondary !important;
			}
    	}

    	@include breakpoint(small) {
			position: absolute;
		    height: 100%;
		    padding: 0;
		    width: 830px;
		    display: inline-block;
		    left: 0;
		}

		@include breakpoint(medium) {
			right: 0;
			left: initial;
		}

		@include breakpoint(large) {
			right: 7%;
		}

		body.iexplore & {
			
			@include breakpoint(medium) {
				right: initial;
				left: 100%;
				transform: translateX(-100%);
			}

			@include breakpoint(large) {
				right: initial;
				left: 100%;
				transform: translateX(-110%);
			}
		}

    	& > div:first-child {

    		@include breakpoint(small) {
				width: 500px;
			    height: 500px;
			    border-radius: 99rem;
			    background-color: #19933a;
			    position: absolute;
			    bottom: 1rem;
    			left: 0;
    		}

    		& > .row {
    			@include breakpoint(small) {
				    position: absolute;
				    top: 50%;
				    left: 50%;
				    transform: translate(-50%, -50%);
				    padding: 0 7%;

				    .col {
				    	@include breakpoint(small) {
				    		padding: 0 2.25rem;
				    	}
				    }
    			}	
    		}

	    	h3 {
	    		color: $light;
		    	padding: 0 10%;
	    		text-align: center;
	    		width: 100%;
	    		font-family: $main-font;
		    	font-size: 1rem;
		    	font-weight: normal;

		    	@include breakpoint(small) {
		    		margin-bottom: 1.25rem;
		    		font-size: 1.125rem;

		    	}
	    		
	    		i {
				    text-indent: -9999px;
				    width: 3.0625rem;
				    height: 1.375rem;
				    display: inline-block;
				    background-image: url(../images/fid-footer.png);
				    background-size: cover;

				    @include breakpoint(small) {
				    	width: 3.6875rem;
    					height: 1.6875rem;
				    }		
				}

		    	 span {
		    	 	position: relative;
		    	 	display: block;
		    	 	border-bottom: 1px solid $light;
		    	 	margin-bottom: .5rem;
		    		font-size: 1.2rem;
		    		padding: 0 0 1rem 0;

		    		@include breakpoint(small) {
		    			font-size: 1.875rem;
		    		}
		    	 }
	    	}

	    	a {
	    		color: $light;

	    	}

	    	ul {

	    		@include breakpoint(small) {
	    			display: flex;
	    			flex-wrap: wrap;
	    			justify-content: space-around;
	    		}

		    	.link-holder {
		    		display: inline-block;
		    		width: 100%;
		    		padding: .5rem 0;
		    		text-align: center;
		    		background-color: rgba(255,255,255,.1);

		    		@include breakpoint(small) {
		    			background-color: transparent;
		    			text-align: left;
		    			flex-basis: 50%;
		    		}

		    		&:nth-child(even) {
		    			background-color: rgba(255,255,255,.3);

		    			@include breakpoint(small) {
		    				background-color: transparent;
		    			}
		    		}

					i {
						color: $light;
						margin-right: .5rem;
					}

			    	a {
			    		text-decoration: none;

			    		@include breakpoint(small) {
			    			text-decoration: underline;
			    		}
			    	}
		    	}
	    	}

	    	p {
	    		color: $light;
	    		margin-top: 1rem;
	    		text-align: center;

	    		@include breakpoint(small) {
	    			margin-bottom: 0;
	    		}

	    		& > a {
	    			@include breakpoint(small) {
	    				display: block;
	    			}
	    		}
	    	}
	    }

	    & > div:last-child {

	    	@include breakpoint(small) {
				display: inline-block;
			    height: calc(572px / 1.5);
			    width: calc(661px / 1.5);
			    background-size: cover;
			    position: absolute;
			    bottom: 0;
			    right: 0;
			    background-image: url(../images/layout/overlay-footer.png);
			}
		}
    }
}

// MISC

.backToTop {color: $light;}
body {font-family: $main-font; margin: 0; padding: 0; background-color: $light;}
noscript    {display: inline !important;}

// PAGE WRAP FÜR SLICK SLIDER BUG

.page-wrap {
	transform: none;
	max-width: 100%;
	overflow:hidden;
}

// ANIMATION
//////////////////////////////

.fadeInRight.wow {
	-webkit-animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	-webkit-animation-name: fadeInRight;
	animation-duration: 1s;
	animation-fill-mode: both;
	animation-name: fadeInRight;
}

.fadeInLeft.wow {
	-webkit-animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	-webkit-animation-name: fadeInLeft;
	animation-duration: 1s;
	animation-fill-mode: both;
	animation-name: fadeInLeft;
}

.fadeInUp.wow {
	-webkit-animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	-webkit-animation-name: fadeInUp;
	animation-duration: 1s;
	animation-fill-mode: both;
	animation-name: fadeInUp;
}

.wow {opacity: 0;

	.cmsbackend & {opacity:1;}

}

@-webkit-keyframes fadeInLeft {
	from {
		opacity: 0;
		-webkit-transform: translate3d(-50px, 0, 0);
		transform: translate3d(-50px, 0, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

@keyframes fadeInLeft {
	from {
		opacity: 0;
		-webkit-transform: translate3d(-50px, 0, 0);
		transform: translate3d(-50px, 0, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}


@-webkit-keyframes fadeInRight {
	from {
		opacity: 0;
		-webkit-transform: translate3d(50px, 0, 0);
		transform: translate3d(50px, 0, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

@keyframes fadeInRight {
	from {
		opacity: 0;
		-webkit-transform: translate3d(50px, 0, 0);
		transform: translate3d(50px, 0, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}


@-webkit-keyframes fadeInUp {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, 50px, 0);
		transform: translate3d(0, 50px, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

@keyframes fadeInUp {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, 50px, 0);
		transform: translate3d(0, 50px, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

.videowrapper {
	float: none;
    clear: both;
    width: 100%;
    position: relative;
    padding-bottom: 45%;
    padding-top: 25px;
    height: 0;
    margin-bottom: 2rem;
    iframe {
		position: absolute;
		border: none;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

label[for="Datenschutz"] {
	display: inline-block;
}