// Icons
@include font-face('FontAwesome',
    'fontawesome-webfont.woff',
    'fontawesome-webfont.ttf',
    'fontawesome-webfont.svg',
    'fontawesome-webfont.eot'
);

@font-face {
	font-family: 'Palanquin-Regular';
	src: url('../css/fonts/Palanquin-Regular.woff2') format('woff2'),
		url('../css/fonts/Palanquin-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Lobster';
	src: url('../css/fonts/Lobster-Regular.woff2') format('woff2'),
		url('../css/fonts/Lobster-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Palanquin-Light';
	src: url('../css/fonts/Palanquin-Light.woff2') format('woff2'),
		url('../css/fonts/Palanquin-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}