// Main Navigation
//////////////////////////////

.navi-add {
	display: none;

	@include breakpoint(small) {
		padding: 0 1.2rem;
		display: inline-block;
		margin-top: 1.875rem;
	}

	a {
		transition: all .3s ease-in;

		&:hover {
			color: $secondary;
		}
	}
}


// Mobile Menu Bar
//////////////////////////////

$menu-bar: 50px;

.page-navi {

	// Off Canvas Navigation
	//////////////////////////////

	background:$primary;
	left:-100%;
	overflow-y: auto;
	position: fixed;
	top:rem($menu-bar);
	transition:.6s;
	width:map-get($breakpoints, tiny) * .8;
	z-index: 1000;
    bottom: 0;
    max-width:100%;

    @include breakpoint(large) {
    	left: 0;
	    position: fixed;
	    top: 0;
	    width: 100%;
	    overflow: visible;
	    height: 108px;
	    background-color: $light;
	    box-shadow: 0px 0px 13px #666;
    }
    nav.row {
		.branding {
			@include breakpoint(large) {
			    display: inline-block;
			    width: auto;
			    height: 108px;
			    background-color: transparent;
			}

			img {
				@include breakpoint(large) {
					width: auto;
				}
			}
		}

		.row {
			@include breakpoint(large) {
				height:100%;
			}
		}

		.navi-main {
			padding: 0;

			@include breakpoint(large) {
				display: flex;
	    		justify-content: space-between;
				padding-left: 1.125rem;
			    padding-right: 1.125rem;
			    height:100%;
			    align-items: center;
			    padding-top: 40px;
			}

			body.iexplore & {
			    padding-top: 30px;
			}	

			li:not(.hasSub) {
				padding: .5rem 0 .5rem 10%;
		    	border-bottom: 1px solid #fff;

		    	@include breakpoint(large) {
		    		padding: 0;
		    		border-bottom: none;
		    	}
			}

			li a, .hasSub span {
				color : $light;
				text-decoration: none;

				@include breakpoint(large) {
					color: $secondary;
					padding: 0.75rem 1rem;
					transition: all .3s ease-in;
					border: 1px solid $light;
				}

				&:hover, &.active {
					@include breakpoint(large) {
						border: 1px solid $secondary;
					}
				}
			}

			.hasSub.active span, .hasSub.active > a {
				@include breakpoint(large) {
					border: 1px solid $secondary;
				}
			}

			li, .hasSub span {
			    display: inline-block;
			    width: 100%;

			    @include breakpoint(large) {
			    	width: auto;
			    }
			}

			li {
			    display: inline-block;
			    width: 100%;

			    @include breakpoint(large) {
			    	width: auto;
			    }

			    &.anfahrt, &.impressum, &.datenschutzerklaerung, &.agbs {
			    	@include breakpoint(large) {
			    		display: none;
			    	}
			    }

				&.hasSub {

					span, & > a {
					    padding: .5rem 0 .5rem 10%;
					    background-color: rgba(0,0,0,0.6);
					    font-weight: bold;
					    width: 100%;
					    display: inline-block;

					    @include breakpoint(large) {
					    	background-color: transparent;
					    	font-weight: normal;
					    	padding: 0;
					    	width: auto;
					    	padding: 0.75rem 1rem;
					    	display: initial;
					    }
					}

					span, & > a {
						&:hover {

							& + .sub {
								@include breakpoint(large) {
									opacity: 1;
									visibility: visible;
								}
							}
						}
					}

					.sub {
						border-top: 1px solid #fff;

						@include breakpoint(large) {
							border-top: none;
							position: absolute;
							top: 6.8rem;
						}

						@include breakpoint(large) {
							visibility: hidden;
							opacity: 0;
							transition: all .3s ease-in;
						}

						&:hover {
							@include breakpoint(large) {
									opacity: 1;
									visibility: visible;
							}
						}
						
						li {
							background-color: rgba(0,0,0,0.3);

							@include breakpoint(large) {
								background-color: $light;
								margin-bottom: 1px;
								border-left: 6px solid $primary;
								transition: all .3s ease-in;
								max-width: 19rem;
								display: block;
							}

							&:hover, &.active {

								@include breakpoint(large) {
									border-left: 6px solid $tertiary;

								}
							}

							a {
								padding: 1rem !important;

								@include breakpoint(large) {
									color: $secondary;
									display: inline-block;

								}

								&:hover, &.active {
									border: 1px solid $light;;
								}


								&:before {
									display: none;

								}
							}

						}

					}
				}
			}
		}
    }
}

.mobile-bar {
	align-items:center;
	background:$primary;
	display: flex;
	height:rem($menu-bar);
	justify-content:stretch;
	left:0;
	position: fixed;
	right:0;
	top:0;
	z-index: 894;
	overflow: hidden;

	@include breakpoint(large) {
		display: none;
	}

	li {
		
		display: inline-block;
		font-size: .8rem;

		@include breakpoint(supertiny) {
			display:flex;
			flex-grow:1;
		}

		&:last-child {
            padding: 1rem;

            @include breakpoint(supertiny) {
            	padding: 0;
            	justify-content:flex-end;
				padding:0 rem($base-gap);
            }

        }

        a {
        	text-decoration: none;
		    background-color: rgba(255,255,255,0.2);
		    padding: 1rem;
		    height: 100%;
		    display: inline-block;

		    .fa-phone {
				
				@include breakpoint(supertiny) {
					margin-right: .5rem;
				}

		    }
        }

        label {
        	font-size: .9rem;
        }
	}

	@include breakpoint(large) {
		display: none;
	}
	label[for="navi-toggled"] {
		color: $light;
	}

	a[href^=tel] {
		color: $light;
	}
}

label[for="navi-toggled"] {
	@extend .fa-bars;
	flex-direction:row-reverse;
	font-size: rem(18px);

	&:before {
		@extend .fa-default;
		margin-right: rem(10px);
	}

	&:after {
		background:rgba($dark,0.8);
		bottom:0;
		content:'';
		left:0;
		opacity: 0;
		pointer-events:none;
		position: fixed;
		top:rem($menu-bar);
		transition:opacity .6s;
		width: 100%;
		z-index:-1;
	}
}

#navi-toggled {
	display: none;

	&:checked {

		~ * label[for="navi-toggled"] {
			@extend .fa-close;

			&:after {
				opacity: 1;
				pointer-events:auto;
			}
		}

		~ * .page-navi,
		~ .page-navi {
			left:0;
		}
	}
}

// Kontaktdaten-li

.h-contact {
	color: $primary;
	background-color: $light;
	width: 100%;
	padding: 0;


	@include breakpoint(large) {
		background-color: transparent;
		color: $secondary;
		padding: 2rem 0 0 0;
	}

	& > span {
		padding: .5rem 0 .5rem 10%;
		display: inline-block;
		width: 100%;
		font-weight: bold;
		border-bottom: 1px solid $primary;

	}

	a {
		color: $primary;

		@include breakpoint(large) {
			color: $secondary;
		}
	}

	ul {
		li {
			padding: .5rem 0 .5rem 10%;
			display: inline-block;
			width: 100%;
			border-bottom: 1px solid $primary;

			@include breakpoint(large) {
				border-bottom: none;
				width: auto;
				padding: 0;
				position: relative;

			}

			&.opentimes {
				padding: .5rem 0 2.25rem 10%;

				@include breakpoint(large) {
					padding: 0;
				}

				span {
					position: absolute;
	    			left: 20%;

	    			@include breakpoint(large) {
	    				width: 12.5rem;
	    				left:initial
	    			}
				}
			}

			&.mail {
				span {
					@include breakpoint(large) {
				  		width: 7.5rem; 
					}
				}
			}

			&.phone {
				span {
					@include breakpoint(large) {
						width: 7rem;
					}
				}
			}

			&:last-child {
				i {
					@include breakpoint(large) {
						margin-right: 0;
					}
				}
			}

			span {
				@include breakpoint(large) {
					opacity: 0;
					visibility: hidden;
					position: absolute;
					display: inline-block;
					padding: .5rem .75rem;
					background-color: $primary;
					text-decoration: none;
					transition: all .3s ease-in;
					color: $light;
					top: 3rem;
				    right: 0;

				}

				&:hover {
					@include breakpoint(large) {
						opacity: 1;
						visibility: visible;
						

					}
				}

				a {
					@include breakpoint(large) {
						color: $light;
					}

					&:hover {
						@include breakpoint(large) {
							color: $secondary;
							transition: all .3s ease-in;
						}
					}
				}
			}

			i {
				margin-right: .5rem;


				@include breakpoint(large) {
					padding: .4rem .5rem;
					border: 1px solid $secondary;
					font-size: 19px;
					margin-right: .2rem;
					display: inline-block;
					transition: all .3s ease-in;
				}

				@include breakpoint(huge) {
					margin-right: 14px;
					padding: .5rem .6rem;
				}

				&:hover {

					@include breakpoint(large) {
						color: $primary;
						border: 1px solid $primary;
					}

					& + span {
						@include breakpoint(large) {
							opacity: 1;
							visibility: visible;
						}
					}

				}
			}
		}
	}
}

// Branding Mobile

.branding {

	position: relative;
    display: none;
    width: 100%;
    height: 5rem;
    background-color: #fff;
    @include breakpoint(large) {
		 display: inline-block;
	}

    img {
		position: absolute;
	    top: 50%;
	    left: 50%;
	    transform: translate(-50%, -50%);
	    width: 45%;
	}
}